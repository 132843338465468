.pagina-restaurante {
  padding-top: 10.5%;
}

.seccion1_p-r {
  background-color: #f4f4f4;
  padding-top: 1%;
  padding-left: 8%;
  padding-right: 8%;
}

.tituloPag-Restaurante {
  color: #55c3ff;
  width: 60%;
  font-size: 1.8em;
}

.rankingSeccion1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2em;
}

.rankingSeccion1 p {
  margin-bottom: 0;
  margin-top: 0;
}

.cantidadOpiniones {
  margin-right: 5px;
}

.separadorUno {
  margin-right: 5px;
}

.ubicacionContacto {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  font-size: 1.2em;
  margin-top: -5px;
  margin-bottom: -5px;
}

.ubicacionContacto p {
  margin-top: -6px;
  margin-bottom: 0;
}

.ubicacion {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-right: 8px;
}

.ubicacion span {
  margin-right: 6px;
  font-size: 1.2em;
  color: gray;
  padding-bottom: 2px;
}

.direccionNegocio {
  color: gray;
}

.contenedorTelefono {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 19px;
}

.contenedorTelefono span {
  padding-bottom: 2px;
  font-size: 1.1em;
  margin-right: 6px;
}

.telefonoNegocio {
  margin-right: 10px;
}

.contenedorMenu,
.contenedorWeb {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 19px;
  margin-right: 8px;
}

.contenedorMenu span,
.contenedorWeb span {
  padding-bottom: 4px;
  font-size: 1.1em;
  margin-right: 4px;
  margin-left: 8px;
}

.contenedorAbierto {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2em;
}

.contenedorAbierto span {
  color: gray;
  font-size: 1.1em;
  margin-right: 4px;
}

.textoHorario {
  color: gray;
  margin-right: 10px;
}

.seccion2_p-r {
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contenedorPresentación {
  width: 40%;
  background-color: #f2f2f2;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contenedorPresentación h3 {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.3em;
}

.contenedorWeb {
    color: black;
    text-decoration: none;
  }

  .contenedorWeb b:hover {
    text-decoration: underline;
  }

  .contenedorMenu{
    cursor: pointer;
  }

  .contenedorMenu p:hover{
    text-decoration: underline;
    }


.subraya_tituloRestaurante {
  background-color: orange;
  height: 4px;
  width: 15%;
}

.galeria_Restaurante {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.galeria_Restaurante img {
  border-radius: 10px;
}

.imagen1Restaurante {
  flex: 1 1 60%;
  width: 60%;
  margin-right: 8px;
  height: 320px;
  object-fit: cover;
}

.galeria_RestauranteAureo {
  width: 40%;
  display: flex;
  height: 320px;
  flex-direction: column;
  margin-right: 20px;
}

.galeria_RestauranteAureo img {
  width: 100%;
  margin-bottom: 8px;
  height: 158px;
  object-fit: cover;
}



.seccion3_p-r {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.division_seccion3_p-r {
  background-color: #f2f2f2;
  width: 29%;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.division_seccion3_p-r h4 {
  margin-bottom: 0;
}

.division_seccion3_p-r p {
  margin-top: 8px;
}

.seccion3_p-r {
  padding-left: 8%;
  padding-right: 13.5%;
}

.calificacionRestaurante {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: -20px;
}

.posicionRestaurante1,
.posicionRestaurante2 {
  font-size: 0.8em;
  margin-top: 0;
}

.tituloCalificaciones {
  font-size: 1.1em;
}

.calificaciones {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.calificaciones h5 {
  font-size: 1em;
  color: gray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
}

.calificaciones .star {
  font-size: 1rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tituloPag-Restaurante {
    color: #55c3ff;
    width: 80%;
    font-size: 1.8em;
    margin-top: 10%;
  }

  .ubicacion {
    margin-bottom: -20px;
  }

  .seccion2_p-r {
    display: flex;
    flex-direction: column-reverse;
  }

  .galeria_Restaurante {
    width: 100%;
    margin-bottom: 17%;
  }

  .contenedorPresentación {
    width: 93%;
    margin-top: -14%;
  }

 
  .seccion3_p-r {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 83%;
    padding: 0;
    padding-left: 8.2%;
  }

  .division_seccion3_p-r {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }

  .division_seccion3_p-r h4 {
    margin-top: 0;
  }
}

@media only screen and (min-width: 487px)  and (max-width: 767px) {
  .tituloPag-Restaurante {
    color: #55c3ff;
    width: 80%;
    font-size: 1.3em;
    margin-top: 10%;
  }

  .rankingSeccion1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .ubicacion {
    margin-bottom: -20px;
  }

  .seccion2_p-r {
    display: flex;
    flex-direction: column-reverse;
  }

  .galeria_Restaurante {
    width: 100%;
    margin-bottom: 14%;
  }

  .galeria_RestauranteAureo img {
    width: 100%;
  }

  .contenedorPresentación {
    width: 93%;
    margin-top: -10%;
  }

  .contenedorWeb {
    color: black;
    text-decoration: none;
  }

  .contenedorWeb b:hover {
    text-decoration: underline;
  }


  .seccion3_p-r {
    display: flex;
    flex-direction: column;
    width: 83%;
    padding: 0;
    padding-left: 8.2%;
  }

  .division_seccion3_p-r {
    width: 93%;
    margin-bottom: 10px;
  }

  .division_seccion3_p-r h4 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 486px){

  .tituloPag-Restaurante{
    padding-top: 10%;
   }
 
   .nombreRestaurante{
     font-size: 2em;
   }

  .seccion2_p-r{
    padding-left: 0%;
    padding-right: 0%;
  }

  .tituloPag-Restaurante {
    color: #55c3ff;
    width: 80%;
    font-size: 1.3em;
    margin-top: 10%;
  }

  .rankingSeccion1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .ubicacion {
    margin-bottom: -20px;
  }

  .seccion2_p-r {
    display: flex;
    flex-direction: column-reverse;
  }

  .galeria_Restaurante {
    width: 96%;
    margin-bottom: 14%;
    margin-left: 4%;
  }

  .galeria_RestauranteAureo img {
    width: 100%;
  }

  .contenedorPresentación {
    width: 90%;
    margin-top: -10%;
  }

  .contenedorWeb {
    color: black;
    text-decoration: none;
  }

  .contenedorWeb b:hover {
    text-decoration: underline;
  }


  .seccion3_p-r {
    display: flex;
    flex-direction: column;
    width: 83%;
    padding: 0;
    padding-left: 7%;
  }

  .division_seccion3_p-r {
    width: 93%;
    margin-bottom: 10px;
  }

  .division_seccion3_p-r h4 {
    margin-top: 0;
  }
}
