.star-rating {
    display: flex;
    justify-content: center;
    margin-right: 7px;
  }
  
  .star {
    font-size: 1.5rem;
    color: grey;
  }
  
  .star.on {
    color: rgb(255, 196, 0);
  }
  