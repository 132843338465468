.contenedorElementos_footer{
    background-color: #50bdfbfd;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;

}

.informacionAdicional{
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-left: 30px;
}

.contenedor_paginasExtra{
    display: flex;
    flex-direction: column;
}

.informacionAdicional a{
    text-decoration: none;
    color: white;
    margin-top: 10px;
}

.informacionAdicional a:hover{
    text-decoration: underline;
}

.logoFooter{
    width: 70%;
    margin-top: 30px;
    margin-bottom: 15px;
}

.contenedorFormulario{
    width: 40%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.formularioFooter{
    display: flex;
    flex-wrap: wrap;
}

.customTextField {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 33%;
    margin-right: 3px;
  }
  
  .customTextField label {
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: #ffffff;
  }
  
  .customTextField input {
    background-color: #ffffff00;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .customTextField input::placeholder {
    color: #ffffff;
  }
  
  .customTextField input:focus {
    border-color: #ffffff; /* Color del borde en foco */
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Sombra en foco */
  }
  
  .customTextField input:hover {
    border-color: #ffffff;
  }
  

.boton_formularioFooter{
    background-color: #AAE5FF;
    font-weight: 900;
    color: white;
    height: 3em;
    border: none;
    border-radius: 10px;
    width: 30%;
    margin-left: 10px;
    cursor: pointer;
}



.redesSociales{
    padding-left: 10%;
}

.icono_rs{
    width: 50px;
}

.creditos{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AAE5FF;
    padding-top: 7px;
}

.creditos p{
    margin-top: 0;
    font-size: 0.8em;
}


@media only screen and (min-width: 768px) and (max-width: 1024px){

.contenedorFormulario{
    width: 60%;
}

.redesSociales{
    margin-top: -5%;
    padding-left: 3%;
}

.icono_rs{
    width: 40px;
}
}


@media only screen and (min-width: 487px) and (max-width: 767px) {
 .contenedorElementos_footer{
 display: flex;
 flex-direction: column;
 }

 .informacionAdicional{
    width: 80%;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
 }

.contenedor_paginasExtra{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.contenedorFormulario{
    width: 80%;
    padding-left: 10%;
}

.redesSociales{
    width: 100%;
    padding-left: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
}

}

@media only screen and (max-width: 486px){


    .contenedorElementos_footer{
        display: flex;
        flex-direction: column;
    }

    .informacionAdicional{
        width: 100%;
        padding-left: 5%;
        padding-left: 0;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .contenedorFormulario{
        width: 80%;
        align-items: center;
        text-align: center;
        padding-left: 10%;
    }

    .formularioFooter{
        display: flex;
        justify-content: center;
    }

    .customTextField {
        width: 80%;
      }

      .redesSociales{
        width: 100%;
        padding-left: 0;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}



