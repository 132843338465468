body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-size: 2.4em;
  }

  h2,
  h3,
  h4,
  h5,
  h6{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }

  p{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  }

  li{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    }

  a{
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  }