.descripcionEventos {
  padding-top: 12%;
  padding-left: 9%;
  padding-right: 9%;
  display: flex;
  flex-direction: row;
}

.seecionPrincipalEvento {
  width: 75%;
}

.seecionPrincipalEvento iframe {
  border-radius: 15px;
}

.seecionPrincipalEvento h1 {
  margin-bottom: 0;
}

.introduccionEvento {
  margin-top: 5px;
  margin-bottom: 10px;
}

.fechaEvento {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.fechaEvento h1 {
  margin-right: 10px;
  color: #55c3ff;
  font-size: 3em;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 900;
}

.mesEvento {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.5em;
}

.horaEvento {
  margin-top: 0;
  margin-bottom: 0;
}

.seecionPrincipalEvento hr{
    width: 95%;
    margin-left: 0;
}

.descripcionEvento {
  margin-bottom: 40px;
  width: 95%;
}

.direccionEvento {
  color: gray;
  font-weight: 800;
}

.comprarenStripe {
    display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  padding: 20px;
  padding-top: 2px;
  border-radius: 10px;
  -webkit-box-shadow: 3px 4px 13px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 3px 4px 13px -4px rgba(0, 0, 0, 0.4);
  box-shadow: 3px 4px 13px -4px rgba(0, 0, 0, 0.4);
}

.ticketImg{
    width: 150px;
}

.stripeLogo{
    width: 100px;
}

.lateralEvento p{
    margin-top: 20px;
    margin-bottom: -7px;
}

.precioEvento{
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 20px;
}

.boton_comprarBoleto{
    background-color: #EF8822;
    color: white;
    font-weight: 700;
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    padding: 5px 30px;
    cursor: pointer;
}

.espacioPublicitario1_evento{
    background-color: rgb(195, 195, 195);
    color: white;
    height: 380px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.espacioPublicitario2_evento{
    background-color: rgb(195, 195, 195);
    color: white;
    height: 280px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.mapaEvento{
    margin-bottom: 80px;
}

