.guia_laPena{
    padding-top: 18%;
    background-color: rgb(255, 255, 255);
}

.logoGuiaSeccion{
    margin-left:80% !important;
    width: 200px;
}

.tituloGuiaSeccion{
    color: #55C3FF;
    font-size: 3em;
    text-align: left;
    padding-left: 0;
    margin-top: -10%;
}


.subtituloBienvenidosGuia{
    text-align: left;
    margin-top: -30px;
}

.informacionPena{
    margin-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
}

.informacionPena iframe{
    border-radius: 15px;
}

.informacionPena_secc1{
    display: flex;
    flex-direction: row;
}

.informacionPena_secc1texto{
    width: 70%;
}

.informacionPena_secc1texto h2{
width: 50%;
}

.informacionPena_secc1 p{
    width: 90%;
    margin-right: 60px;
}

.informacionPena_secc2{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.informacionPena_secc3{
    text-align: center;
}

.informacionPena_secc3 li{
    list-style: none;
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 1.1em;
}

.galeria1_detallesTuristicos{
    width: 360px;
    margin-right: 70px;
}

.indicaciones_turistas{
    margin-bottom: 60px;
    width: 50%;
    margin-left: 25%;
}

.flexbox_Necesarios{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.flexbox_Necesarios ul {
    padding-inline-start: 20px;
}

.contenedor_galeriaInfinita{
    overflow: hidden;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 50px;
}

.galeriaInfinita{
    display: flex;
    animation: scroll 60s linear infinite; /* Ajusta el tiempo según tu preferencia */
    width: calc(220px * 10 + 15px * 10); /* Número de imágenes * ancho de imagen + margen entre imágenes */
}

@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }


.img_galeriaInfinita{
    width: 220px;
    height: 220px;
    object-fit: cover;
    margin-right: 15px;
}

.mapa_turista{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mapa_turista h2{
    margin-bottom: 5px;
    color: #55C3FF;
    font-size: 3em;
}

.mapa_turista p{
    margin-top: 0;
}


@media only screen and (min-width: 768px) and (max-width: 1024px){
    .guia_laPena {
        padding-top: 20%;
      }

    .logoGuiaSeccion {
        width: 120px;
        margin-top: 2%;
      }
    
      .tituloGuiaSeccion {
        margin-top: -14%;
      }
    
      .informacionPena {
        padding: 0 6%;
      }
    
      .informacionPena_secc1 {
        flex-direction: column;
      }
    
      .informacionPena_secc1texto {
        width: 100%;
        text-align: center;
      }
    
      .informacionPena_secc2 {
        flex-direction: column-reverse;
      }
    
      .informacionPena_secc1 p {
        margin-right: 0;
        width: 100%;
      }

      .informacionPena_secc1 iframe{
        padding-left: 7%;
        margin-top: 20px;
      }

      .texto2_informacion h2{
        width: 100%;
      }

      .informacionPena_secc3{
        width: 40%;
        text-align: left;
        margin-top: -40%;
        padding-left: 50%;
      }

      .indicaciones_turistas{
width: 70%;
margin-left: 15%;
      }

      .botonesSeccionesGuia{
        width: 90%;
        margin-left: 5%;
      }

      .boton_Emergencia{
        margin-left: 5%;
      }
    }

@media only screen and (min-width: 487px) and (max-width: 760px) {
    .guia_laPena {
        padding-top: 12%;
      }
    
      .logoGuiaSeccion {
        margin: 0 auto;
        width: 150px;
      }
    
      .tituloGuiaSeccion {
        font-size: 2em;
        margin-top: -5%;
        text-align: center;
      }
    
      .subtituloBienvenidosGuia {
        text-align: center;
        margin-top: -20px;
      }
    
      .informacionPena {
        padding: 0 5%;
        margin-top: 5%;
      }
    
      .informacionPena_secc1 {
        flex-direction: column;
      }

      .informacionPena_secc1 iframe{
        padding-left: 0%;
        margin-left: 0%;
        margin-top: 20px;
        width: 100%;
      }

    
      .informacionPena_secc1texto {
        width: 100%;
        text-align: center;
      }
    
      .informacionPena_secc1 p {
        width: 100%;
        margin: 0 auto;
      }
    
      .informacionPena_secc2 {
        flex-direction: column-reverse;
        margin-top: 20px;
      }
    
      .galeria1_detallesTuristicos {
        width: 100%;
        margin: 0 auto 20px;
      }
    
      .indicaciones_turistas {
        width: 90%;
        margin: 0 auto;
      }
    
      .flexbox_Necesarios {
        flex-direction: column;
      }
    
      .contenedor_galeriaInfinita {
        width: 100%;
        margin-left: 0;
      }
    
      .mapa_turista h2 {
        font-size: 2em;
      }

      .mapa_turista iframe{
        width: 90%;
      }
}

@media only screen and (max-width: 486px){
  .contenedor_logoGuia{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
  }

  .logoGuiaSeccion{
    margin-left: 7% !important;
  }

  .tituloGuiaSeccion {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
  }

  .subtituloBienvenidosGuia{
    text-align: center;
  }

  .informacionPena{
    padding-left: 3%;
    padding-right: 2%;
    text-align: center;
  }


  .informacionPena_secc1,
  .informacionPena_secc2 {
    flex-direction: column;
  }

  .informacionPena_secc1texto{
    width: 100%;
  }

  .informacionPena_secc1texto h2{
    width: 100%;
  }

  .informacionPena_secc1 iframe{
    width: 100%;
    border-radius: 0;
    padding-left: 0%;
    margin-left: 0%;
    margin-top: 20px;
    width: 100%;
  }

  .informacionPena_secc1 p{
margin-right: 0;
width: 100%;
  }

  .informacionPena_secc2 {
    flex-direction: column-reverse;
    text-align: left;
    margin-top: 20px;
  }

  .indicaciones_turistas{
    width: 80%;
    margin-left: 10%;
    text-align: center;
  }

  .flexbox_Necesarios{
    display: flex;
    flex-direction: column;
  }

  .mapa_turista iframe{
    width: 90%;
  }

 

 
}
