.contenedor_botonesGuia {
  margin-bottom: 40px;
}

.botonesSeccionesGuia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 80%;
  margin-bottom: 5px;
  margin-left: 10%;
  margin-top: 0.3%;
}

.boton_seccionGuia {
  text-decoration: none;
  color: black;
  border: 0.5px solid gray;
  padding: 20px;
  width: 28%;
  height: 100px;
  border-radius: 10px;
  -webkit-box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.icono_GuiaBoton {
  width: 60px;
  height: 60px;
}

.boton_Emergencia {
  background-color: #ff4e4e;
  border-radius: 30px;
  border: none;
  padding: 0px 20px;
  color: white;
  font-size: 1em;
  margin-left: 10%;
  -webkit-box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.15);
  margin-top: 4px;
  cursor: pointer;
}

/* Estilos para la ventana emergente */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 80%;
}

.close-popup {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff4e4e;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (min-width: 781px) and (max-width: 1024px) {

}

@media only screen  and (min-width: 487px) and (max-width: 780px) {}

@media only screen and (max-width: 486px){

  .botonesSeccionesGuia {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 90%;
    margin-left: 5%;
  }


.boton_seccionGuia {
  text-decoration: none;
  color: black;
  border: 0.5px solid gray;
  padding: 20px;
  width: 80%;
  margin-bottom: 10px;
}

.contenedor_botonEmergencia{
  display: flex;
  align-items: center;
  justify-content: center;
}

.boton_Emergencia{
  margin-left: 0;
}
}




