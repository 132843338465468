.contenedor_paginaPropiedad hr{
  opacity: 30%;
}

.contenedor_paginaPropiedad{
    padding-top: 13.6%;
}

.descripcionGeneral_propiedad img{
  border-radius: 10px;
}

.encabezadoArticulo div{
  display: flex;
  flex-direction: row;
}

.titulo_propiedad{
    color: #2AA2FF !important;
    margin-top: 0;
    margin-bottom: 0;
}

.galeriaPropiedad{
    display: flex;
    margin-left: 8%;
    height: 450px;
}

.foto_principal{
width: 50%;
height: auto;
object-fit: cover;
}

.fotos_secundarias{
    display: flex;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Optional: space between images */
    justify-content:flex-start; /* Optional: center the grid */
    margin-left: 10PX;
    height: 400px;
    width: 50%;
}

.fotoSecundaria1,
.fotoSecundaria2,
.fotoSecundaria3,
.fotoSecundaria4{
    flex: 1 0 45%; /* Adjusts the size of the images to fit into 2x2 grid */
    max-width: 40%; /* Ensures images do not overflow */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    height: 220px;
   object-fit: cover;
}

.infoproPiedades{
    display: flex;
    margin-left: 8%;
}

.detallesPropiedades{
    width: 56%;
}

.contactoPropiedades{
    width: 40%;
}

.contenedor_ubicacion{
    display: flex;
    align-items: center ;
}

.contenedor_ubicacion span{
    margin-right: 7px;
}

.preinfo_propiedad{
    color: gray;
    font-size: 1.3em;
    margin-bottom: 0;
    margin-top: 20px;
}

.precio_propiedad{
    margin-top: 5px;
    font-size: 1.7em;
}

.contenedor_ubicacion{
font-size: 1em;
font-weight: bolder;
}

.contenedor_ubicacion h5{
    margin-top: 0;
}

.contenedor_ubicacion span{
opacity: 30%;
margin-top: -20px;
}

.caracteristicas button{
 background-color: #17B8FF;
 border: none;
 border-radius: 7px;
 padding: 10px 5px;
 color: white;
 width: 25%;
 max-height: 50px;
 align-items: center;
 cursor: pointer;
}

.icono_carGen{
    max-width: 20px;
    max-height: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
}

.tabs{
    display: flex;
    flex-direction: row;
}

  button.active {
    background-color: #17B8FF;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
  }
  
  button.inactive {
    background-color: transparent;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
  
  }

  button span {
    fill: gray;
    transition: fill 0.3s ease;
    margin-right: 5px;
  }

  .información_anunciante{
    display: flex;
    flex-direction: row;
  }

  .logo_anunciante{
    margin-left: 10px;
    margin-right: 40px;
  }

  .información_anunciante h2{
    margin-bottom: 0;
  }

  .información_anunciante p{
margin-top: 0;
  }

  .contacta_contenedor{
    border: 0.5px solid rgba(0, 0, 0, 0.695);
    border-radius: 20px;
    width: 70%;
    margin-left: 30px;
    padding: 25px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  .contacta_contenedor a{
    display: flex;
    flex-direction: row;
  }
  
  .boton_terminosCondiciones{
    cursor: pointer;
  }

  .boton_terminosCondiciones:hover{
    text-decoration: underline;
  }

  .contenedor_telefonoFormulario{
    display: flex;
    flex-direction: row;
    border: 0.5px solid rgba(0, 0, 0, 0.695);
    border-radius: 20px;
    width: 70%;
    margin-left: 30px;
    padding: 25px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 10px;
    align-items: center;
  }

  .contenedor_logoTelefono{
    width: 40%;
  }

  .logo_telefono{
    width: 80%;
  }

  .informacion_telefono div{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .informacion_telefono h4{
    margin-bottom: 5px;
  }

  .informacion_telefono h3{
   margin-right: 5px;
   margin-top: 5px;
  }
  
  .informacion_telefono p{
    font-weight: bolder;
    font-size: 0.7em;
    color: gray;
    cursor: pointer;
    margin-top: -2px;
  }

  .addPropiedad1{
    color: white;
    background-color: #DDDDDD;
    border-radius: 20px;
    width: 70%;
    height: 320px;
    margin-left: 30px;
    padding: 25px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30PX;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .contenedor_paginaPropiedad{
      padding-top: 15%;
  }
  

    .encabezadoArticulo div{
      display: flex;
      flex-direction: row;
    }

  
    .titulo_Articulo{
      width: 50%;
    }

    .foto_principal{
      width: 40%;
      }

    .galeriaPropiedad{
      margin-left:11% ;
    }

    .infoproPiedades{ 
      display: flex;
      flex-direction: column;
    }

    .detallesPropiedades{
      width: 90%;
    }

    .contactoPropiedades{
    display: flex;
    flex-direction: row;
    width: 90%;
   }

   .contacta_contenedor{
    margin-left: 0;
    margin-top: 4%;
    width: 90%;
   }
  }

  @media only screen and (max-width: 767px) {

    .contenedor_ecabezadoHospedaje button{
     display: none;
    }

    .encabezadoArticulo div{
      display: flex;
      flex-direction: row;
    }

  
    .titulo_Articulo{
      margin-top: 0;
      text-align: center;
    }

    .foto_principal{
      width: 40%;
      }

    .galeriaPropiedad{
      display: flex;
      flex-direction: column;
      margin-left:10% ;
      width: 90%;
      margin-bottom: 80px;
    }

    .foto_principal{
      width: 90%;
      height: 180px;
      margin-bottom: 10px;
    }

    .fotos_secundarias{
      margin-left: 0;
      width: 100%;
    }

    .fotoSecundaria1, 
    .fotoSecundaria2, 
    .fotoSecundaria3, 
    .fotoSecundaria4{
      flex: 1 0 44%;
      max-width: 44%;
      box-sizing: border-box;
      height: 170px;
  }
    


    .infoproPiedades{ 
      display: flex;
      flex-direction: column;
    }

    .detallesPropiedades{
      width: 90%;
    }

    .tabs {
      display: flex;
      flex-wrap: wrap;
      gap: 10px; 
      
    }

    .tabs button {
      flex: 1;
      padding: 10px;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }

    .contactoPropiedades{
    display: flex;
    flex-direction: column;
    width: 90%;
   }

   .contacta_contenedor{
    margin-left: 0;
    margin-top: 20px;
    width: 90%;
   }

   .contenedor_telefonoFormulario{
    width: 90%;
    margin-left: 0;
   }

   .addPropiedad1{
    margin-left: 0;
    width: 90%;
    text-align: center;
   }
  }