.articulos_recientes{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.articulos_principales{
    width: 35%;
    padding-right: 10px;
}

.contenedor_artPrinc{
    cursor: pointer;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
.contenedor_artPrinc:hover {
      transform: scale(1.02); /* Escala del aumento de tamaño */
  }

.articulos_secundarios{
    width: 65%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.contenedor_artSec{
    flex: 1 0 40%; /* Cada item ocupará el 50% del ancho del contenedor */
    box-sizing: border-box;
    margin-left: 20px;
    height: 30%;
    margin-bottom: 3%;
    cursor: pointer;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .contenedor_artSec:hover {
      transform: scale(1.02); /* Escala del aumento de tamaño */
  }

.articulos_principales img{
    height: 230px;
    width: 100%;
    object-fit: cover;
}

.articulos_secundarios img{
    height: 187PX;
    width: 100%;
    object-fit: cover;
}

.categoria_fecha{
display: flex;
flex-direction: row;
align-items: center;
height: 2em;
}

.categoria_cont{
    background-color: #EF8822;
    padding: 3px 20px;
    margin-right: 10px;
    color: white;
    font-size: 0.7em;
    font-weight: 900;
}

.Fecha{
    color: gray;
    font-weight: 0.8em;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .articulos_recientes{
        display: flex;
        flex-direction: column;
    }

    .articulos_principales{
        display: flex;
        flex-direction: ROW;
        width: 100%;
        justify-content: space-between;
    }

    .contenedor_artPrinc{
        width: 49%;
    }

    .articulos_secundarios{
        width: 100%;
    }


    .contenedor_artSec{
        flex: 1 0 30%; /* Cada item ocupará el 50% del ancho del contenedor */
        height: 30%;
        margin-bottom: 3%;
        cursor: pointer;
        transition: transform 0.3s ease;
        cursor: pointer;
        margin-left: 0;
        margin-right: 2%;
      }
}

@media only screen and (min-width: 487px) and (max-width: 767px){
    .articulos_recientes{
        display: flex;
        flex-direction: column;
    }

    .articulos_principales{
        display: flex;
        flex-direction: ROW;
        width: 100%;
        justify-content: space-between;
    }

    .contenedor_artPrinc{
        width: 49%;
    }

    .articulos_secundarios{
        width: 100%;
    }


    .contenedor_artSec{
        flex: 1 0 40%; /* Cada item ocupará el 50% del ancho del contenedor */
        height: 30%;
        margin-bottom: 3%;
        cursor: pointer;
        transition: transform 0.3s ease;
        cursor: pointer;
        margin-left: 0;
        margin-right: 2%;
      }

}

@media only screen and (max-width: 486px){
    .articulos_recientes{
        display: flex;
        flex-direction: column;
    }

    .articulos_principales{
        width: 100%;
    }

    .articulos_secundarios{
        width: 100%;
    }
    .contenedor_artSec{
        flex: 1 0 40%; /* Cada item ocupará el 50% del ancho del contenedor */
        margin-bottom: 3%;
        cursor: pointer;
        transition: transform 0.3s ease;
        cursor: pointer;
        margin-left: 0;
        margin-right: 2%;
      }
}
