.catalogo_propiedades {
  padding-top: 13%;
  padding-left: 12%;
  padding-right: 12%;
}

.tituloBusqueda_realEstate {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.encabezado_hospedaje {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoguia_hospedaje {
  width: 150px;
  margin-right: 20px;
}

.titulo_realEstate {
  width: 50%;
  color: #2aa2ff;
  font-size: 2.3em;
}

.contenedor_busquedaRE {
  width: 20%;
  position: relative;
  padding-left: 30%;
  margin-bottom: 0;
}

/* Añade estos estilos al final de tu archivo restaurantes_style.css */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #0056b3;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}

@media only screen and (min-width: 487px) and (max-width: 767px) {
}

@media only screen and (max-width: 486px){
  .encabezado_hospedaje{
    margin-top: 40px;
  }

  .logoguia_hospedaje{
    width: 100px;
  }

  .catalogo_propiedades{
    padding-left: 5%;
    padding-right: 5%;
  }
}
