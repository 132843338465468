.contenedor_cta_guia{
    display: flex;
    flex-direction: row;
    background-color: #E8FBFD;
    -webkit-box-shadow: inset 0px 25px 33px -15px rgba(0,0,0,0.18);
-moz-box-shadow: inset 0px 25px 33px -15px rgba(0,0,0,0.18);
box-shadow: inset 0px 25px 33px -15px rgba(0,0,0,0.18);
justify-content: space-between;
height: auto;
}

.pretitulo_guia{
width: fit-content !important;
padding-top: 10%;
margin-bottom: 0;
}

.titulo_guia{
    color: #EF8822;
    font-weight: 900;
    font-size: 2.2em;
    margin-top: 0;
    margin-bottom: 5px;
}


.imagen1_guiaturistica{
    width: 30%;
    margin-top: 5%;
    margin-left: 3%;
}

.pretitulo_guia{
    width: 40%;
   
}

.texto_guiaturistica{
    padding-left: 6px;
}

.imagen2_guiaturistica{
    width: 20%;
    padding-right: 5%;
    margin-top: 5%;
}

.boton_guia{
    width: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .boton_guia{
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .contenedor_cta_guia{
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 80px;
    }
    .imagen1_guiaturistica{
        width: 50%;
    }
    .contenedor_cta_guia p{
        width: 80%;
        padding-left: 10%;
    }
    .pretitulo_guia{
        padding-top: 0;
        margin-top: 0;
    }
    .boton_guia{
        margin-bottom: 25px;
    }

}
