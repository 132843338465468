.contenedor_novedades {
  display: flex;
  flex-direction: row;
  width: 88%;
  margin-left: 6%;
  margin-bottom: 15px;
  justify-content: space-between;
  height: 50vh;
}

.articuloprinc_contenedor {
  background-image: url("https://www.paraglidingmexico.com/templates/yootheme/cache/penon0032-73bb51f4.jpeg");
  background-size: cover; /* Ajusta la imagen para cubrir todo el fondo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center center; /* Centra la imagen */
  width: 45%;
  margin-top: 5px;
  height: 97%;
  margin-right: 10px;
  border-radius: 10px;
}

.contenedorTitulo {
  background-color: white;
  opacity: 90%;
  width: 75%;
  border-radius: 0px 40px 40px 0px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding-left: 4%;
  font-size: 83%;
}

.Titulo {
  margin-bottom: 0;
  margin-top: 5px;
}

.desc_breve {
  margin-top: 0;
  margin-bottom: 7px;
}

.boton_redondo {
  top: 56%;
  left: 82%;
  font-size: 2em !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal;
}

.articulosec_contenedor {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.articulo_sec1,
.articulo_sec2,
.articulo_sec3,
.articulo_sec4,
.articulo_sec5,
.articulo_sec6 {
  background-size: cover; /* Ajusta la imagen para cubrir todo el fondo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center center; /* Centra la imagen */
  border-radius: 10px;
  margin: 5px;
  flex: 1 1 30%;
}

.articulo_sec1 {
  background-image: url("https://media.staticontent.com/media/pictures/65a37fb6-fa4b-4c0b-9344-a0d4e9f62599");
}

.articulo_sec2 {
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPLktjz4U1lZ8rG-iEnHviT7edoLQ0MOCfgw&s");
}

.articulo_sec3 {
  background-image: url("https://cf.bstatic.com/xdata/images/hotel/max1024x768/72908319.jpg?k=8d9728c355cb49045c8515d45ef750e8c3a33e9adbc2c8676e6b396dc69f4f10&o=&hp=1");
}

.articulo_sec4 {
  background-image: url("https://cdn-3.expansion.mx/dims4/default/857cbef/2147483647/strip/true/crop/2120x1415+0+0/resize/1200x801!/format/webp/quality/60/?url=https%3A%2F%2Fcherry-brightspot.s3.amazonaws.com%2Fd5%2Fb7%2F9a5342ce4d209e046f9e06d6f625%2Fistock-508411976.jpg");
}

.articulo_sec5 {
  background-image: url("https://cf.bstatic.com/xdata/images/hotel/max1024x768/558911616.jpg?k=4e1b3212d9985eda3288800d502f598fb2f5946bc515f9e67cdc30e255b0da02&o=&hp=1");
}

.articulo_sec6 {
  background-image: url("https://alephmultimedios.com/wp-content/uploads/WhatsApp-Image-2022-01-15-at-8.51.50-AM-3.jpeg");
}

.boton_redondo2 {
  position: absolute;
  top: 15%;
  left: 90%;
  transform: translate(-50%, -50%);
  font-size: 1.3em !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 900 !important;
  font-style: normal;
  z-index: 10; /* Asegura que esté por encima de otros elementos */
}

.contenedorTitulo2 {
  background-color: white;
  opacity: 95%;
  width: 70%;
  border-radius: 0px 40px 40px 0px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  font-size: 95%;
}

.Titulo2 {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 0.9em;
}

.desc_breve2 {
  margin-top: 0;
  margin-bottom: 7px;
  font-size: 0.7em;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .contenedor_novedades {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .articuloprinc_contenedor {
    width: 100%;
    margin-bottom: 5px;
  }


  .boton_redondo {
    top: -20%;
    left: 90%;
    font-size: 2.3em !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 900 !important;
    font-style: normal;
  }

  .contenedorTitulo2{
    margin-top: 90px;
    margin-bottom: 20px;
  }
  
}

@media only screen and (min-width: 487px) and (max-width: 767px) {
  .contenedor_novedades {
    display: flex;
    flex-direction: column;
    height: 120vh;
  }

  .articuloprinc_contenedor {
    width: 100%;
    margin-bottom: 4px;
  }

  .articulosec_contenedor {
    width: 100%; /* Asegúrate de que el contenedor ocupe el 100% del ancho disponible */
    display: grid;
    grid-template-columns: repeat(2,1fr); /* Define dos columnas de igual tamaño */
    gap: 6px; /* Espacio entre los elementos */
    margin-left: -5px;
  }

  .articulo_sec1,
  .articulo_sec2,
  .articulo_sec3,
  .articulo_sec4,
  .articulo_sec5,
  .articulo_sec6 {
    background-size: cover; /* Ajusta la imagen para cubrir todo el fondo */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-position: center center; /* Centra la imagen */
    border-radius: 15px;
    margin: 5px;
    width: 100%; /* Asegúrate de que los elementos ocupen el 100% del ancho de la columna */
  }

  .contenedorTitulo2{
    margin-top: 70px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 486px){
  .contenedor_novedades {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .articuloprinc_contenedor {
    width: 100%;
    margin-bottom: 4px;
  }

  .articulosec_contenedor {
    width: 100%; /* Asegúrate de que el contenedor ocupe el 100% del ancho disponible */
    display: grid;
    grid-template-columns: repeat(1,1fr); /* Define dos columnas de igual tamaño */
    gap: 6px; /* Espacio entre los elementos */
    margin-left: -5px;
  }

  .articulo_sec1,
  .articulo_sec2,
  .articulo_sec3,
  .articulo_sec4,
  .articulo_sec5,
  .articulo_sec6 {
    background-size: cover; /* Ajusta la imagen para cubrir todo el fondo */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-position: center center; /* Centra la imagen */
    border-radius: 15px;
    margin: 5px;
    width: 100%; /* Asegúrate de que los elementos ocupen el 100% del ancho de la columna */
  }

  .contenedorTitulo2{
    margin-top: 70px;
    margin-bottom: 10px;
  }
}
