.directorioNegocios{
    padding-top: 190px;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 60px;
}  

  .alphabet-filter {
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    margin: 10px 0;
    padding: 10px;
    padding-left: 25px;
    border-radius: 30px;
  }
  
  .alphabet-filter button {
    margin: 5px 5px;
    padding: 10px;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: #ffffff;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 9px -2px rgba(138,138,138,0.68);
    -moz-box-shadow: 0px 0px 9px -2px rgba(138,138,138,0.68);
    box-shadow: 0px 0px 9px -2px rgba(138,138,138,0.68);
  }

  .alphabet-filter button:hover{
    color:#2AA2FF ;
  }
  
  .alphabet-filter .selected {
    background-color: #2AA2FF;
    color: white;
  }

  .alphabet-filter .selected:hover{
    color: white;
  }
  
  .alphabet-filter .emergency-button {
    width: 180px;
    margin-left:auto;
    background-color: #FF4E4E;
    color: white;
  }
  
  .content {
    display: flex;
  }
  
  .category-list {
    background-color: #F7F7F7;
    border-radius: 30px 0 0 30px;
    flex: 1;
    padding: 10px;
    overflow: hidden;
    
  }
  
  .category-list button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #ffffff;
    border: 0.5px solid gray;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .category-list button:hover{
    background-color: #b8e1f8;
    border: 0.5px solid #b8e1f8;
  }

  
  .business-container {
    background-color: #F7F7F7;
    border-radius: 0 30px 30px 0;
    flex: 3;
    padding: 10px;
  }
  
  .business-list {
    display: flex;
    flex-wrap: wrap;
  }

  .business-container h2{
    color: #55C3FF;
  }

  .box_Negocios{
    background-color: white;
    height: 78%;
    border-radius: 30px;
    padding-left: 10px;
    padding-top: 10px;
    overflow-y: auto;
  }

  .lista_deNegocios{
    background-color: white;
  }
  
  .business-card {
    width: calc(30% - 10px);
    margin: 5px;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    border-radius: 20px;
    position: relative;
    display: flex;
  flex-direction: column;
  align-items: center;
  }

  .business-card h3{
    margin-bottom: 0;
  }
  
  .business-card img {
    max-width: 60%;
    height: auto;
    max-height: 50px;
    object-fit: contain; 
}
  .business-card h5{
    color: gray;
    margin-bottom: 0;
    margin-top: 0;
  }

  .business-card h4{
    color: #EF8822;
    margin-top: 8px;
  }

  .boton_verNegocio{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: none;
    background-color: #2AA2FF;
    color: white;
    font-size: 2em;
    display: flex;
    margin-left: 85%;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 15px;
  }


  @media only screen and (min-width: 821px) and (max-width: 1160px) {
    .directorioNegocios {
        padding-top: 15%;
    }

    .business-list {
        display: flex;
        flex-wrap: wrap;
    }

    .business-card {
        width: calc(43% - 10px); /* Ajustar para tener en cuenta los márgenes */
        margin: 10px; /* Aumentar el margen para igualarlo */
    }
}


  @media only screen and (min-width: 487px) and (max-width: 820px) {
    .directorioNegocios{
      padding-top: 18%;
      padding-left: 1%;
      padding-right: 1%;
    }

    .alphabet-filter button{
       width: 30px;
       height: 40px;
    }

    .content{
      width: 100%;
    }

    .business-list{
      display: flex;
      flex-direction: column;
    }

    .business-card {
      width: calc(90% - 10px);
      min-width: 270px;
      margin: 5px;
      text-align: left;
      display: flex;
      flex-direction: row;

    }

    .business-card img{
      margin-right: 10px;
    }

   
  }

    
  @media only screen and (max-width: 486px){
    .directorioNegocios{
      padding-top: 25%;
      padding-left: 1%;
      padding-right: 1%;
    }

    .alphabet-filter button{
       width: 30px;
       height: 40px;
    }

    .content{
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .category-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      border-radius: 30px;
      margin-bottom: 20px;
    }

    .category-list button{
      width: calc(30% - 10px);
    }

    .business-container{
      border-radius: 30px;
    }

    .business-list{
      display: flex;
      flex-direction: column;
    }

    .business-card {
      width: calc(90% - 10px);
      min-width: 270px;
      margin: 5px;
      text-align: left;
      display: flex;
      flex-direction: row;

    }

    .business-card img{
      margin-right: 10px;
    }

  }



  

