.proxeve_contenedor{
    margin-bottom: 30px;
    margin-right: 8%;
    margin-left: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    overflow-x: scroll; /* Permite desplazamiento horizontal */
}

.evento_proximo{
    margin-right: 30px;
}

.foto_eventoproximo{
    width: 230px;
    height: 320px;
}

.titulo_eventoproximo{
    margin-top: 2px;
    margin-bottom: 0;
}

.info_eventoproximo{
    margin-top: 2px;
}