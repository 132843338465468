.encabezado_Restaurantes{
  padding-left: 10%;
  padding-right: 10%;
}

.titulos_encRes{
  padding-top: 14%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo_guiaRestaurantes{
  width: 150px;
}

.tituloRestaurantes{
font-size: 2em;
width: 60%;
margin-left: 20px;
}


.restaurant-list{
    margin-left: 8%;
    margin-bottom: 60px;
}

.filter {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 1%;
    background-color: #F5F5F5;
    border: 0.5px solid #707070;
    border-radius: 7px;
    height:max-content;
    width: 22%;
    padding-left: 15px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .filter input[type="checkbox"] {
    display: none;
  }
  
  .filter input[type="checkbox"] + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
    color: #000000;
    font-size: 14px;
  }
  
  .filter input[type="checkbox"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1.5px solid #ccc;
    border-radius: 4px;
    background-color: white;
  }
  
  .filter input[type="checkbox"]:checked + label:before {
    background-color: #000000;
    border-color: #000000;
  }

  .filter input[type="checkbox"]:checked + label:after {
    content: "✔";
    position: absolute;
    left: 3px;
    top: 0;
    width: 20px;
    height: 20px;
    font-size: 18px;
    color: white;
    text-align: center;
    line-height: 20px;
  }

  .filter div{
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
  }


  .restaurant-list {
    display: flex;
    padding-right: 10%;
  }
  
  .cards {
    width: 90%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
   justify-content: flex-start;
   padding-left: 40px;
   margin-top: 1%;
  }
  
  .restaurant-card {
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 10px;
  }


  .restaurant-card img {
    width: 240px;
    min-width: 220px;
    height: 100%;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 8px 0 0 8px;
  }

  .informacionRestaurante{
    width: 80%;
    padding-right: 20px;
  }

  .nombreUbicacion_restaurante{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .nombreUbicacion_restaurante a{
    text-decoration: none;
    color: #000000;
  }

  .nombreUbicacion_restaurante a:hover{
    text-decoration: underline;
    color: #000000;
  }

  .restaurant-card h3{
    font-size: 1.3em;
    margin-top: 10px;
    margin-bottom: 7px;
  }

  .restaurant-card h4{
    color: #818181;
    margin-top: 10px;
    margin-bottom: 7px;
  }

  .restaurant-card hr{
    opacity: 40%;
  }

  .horarioRestaurante{
    margin-top: 0;
    margin-bottom: 0;
  }

  .division_comidaPrecioMenu{
    display: flex;
    flex-direction: row;
  }

  .division_comidaPrecioMenu p{
    margin-bottom: 12px;
    font-size: 0.8em;
    font-weight: 500;
  }
  

  .puntoSeparador{
    margin-right: 3px;
    margin-left: 3px;
  }

  .descripcionRestaurante{
    font-size: 0.9em;
    font-weight: 240;
  }
  
  .buttons {
    position: relative;
    bottom: 0;
    right: 2%;
    display: flex; 
    gap: 10px; 
    margin: 10px; 
  }

  .botonReservar{
    background-color: #EF8822;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
   color: white ;
   font-size: 1em;
   cursor: pointer;
   font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }

  .botonReservar:hover{
    background-color: #e88018;
  }


  .botonUbicacion_restaurantes{
    background-color: #17B8FF;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
   color: white ;
   font-size: 1em;
   cursor: pointer;
   font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }

  .botonUbicacion_restaurantes:hover{
    background-color: #06aaf0;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .titulos_encRes{
      padding-top: 18%;
    }

    .restaurant-list{
      display: flex;
      flex-direction: column;
    }
    
    .filter{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     width: 70%;
     padding-right: 30%;
    }
  
    .tituloRestaurantes{
      font-size: 1.8em;
    }
  }

@media only screen and (max-width: 767px) {
  .encabezado_Restaurantes{
    padding-left: 3%;
    padding-right: 3%;
    padding-top:8% ;
  }

  .restaurant-list{
    display: flex;
    flex-direction: column;
  }

  .filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 94%;
    padding-right: 2%;
   }

   .filter:nth-child(2){
    margin-right: 1%;
   }

   .tituloRestaurantes{
    font-size: 1.3em;
  }

  .restaurant-list{
    padding-right:3% ;
    margin-left: 3%;
  }

  .cards {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
   justify-content: flex-start;
   padding-left: 0%;
   margin-top: 1%;
  }

  .restaurant-card{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .image-container img{
    width: 100%;
    height: 20vh;
    border-radius: 0;
  }

  .informacionRestaurante{
    padding-left: 15px;
    width: 90%;
  }

  .buttons{
    margin-left: 25%;
  }

}

