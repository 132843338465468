.pagina_eventos{
    padding-top: 12%;
}

.titulo_eventos{
    padding-left: 10%;
}

.contexto_eventos{
    margin-left: 10%;
    margin-right: 10%;  
    margin-top: 0;
    margin-bottom: 7px;
}

.subtitulo_eventos{
    background-color: #F7F7F7;
    padding-top: 20PX;
    padding-bottom: 25PX;
    margin-bottom: 0;
    text-align: center;
    font-size: 2em;
}

.subtitulo_eventosProximos{
    background-color: #F7F7F7;
    padding-top: 20PX;
    padding-bottom: 25PX;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    align-items: center;
    font-size: 2em;
}

.subtitulo_eventosProximos a{
    color: #EF8822;
    padding-left: 1%;
}