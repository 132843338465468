.slideshow {
    background-color: #e6f7ff;
    padding: 20px;
    border-radius: 10px;
    width: 75%;
    margin-left: 11%;
    height: auto;
    padding-bottom: 40px; /* Cambia a auto para que se ajuste automáticamente */
    margin-bottom: 20PX;
  }
  
  .slide {
    display: flex;
    flex-direction: column; /* Cambia a columna para mejor alineación */
    align-items: center;
  }
  
  .slide-content {
    max-width: 100%;
    text-align: center; /* Centrar el texto */
  }

  .tags{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 20%;
    margin-left: 40%;
  }

  .tags_diapositiva{
    font-size: 0.3em;
    
  }

  
  .separador_ti {
    display: flex;
    flex-direction: column; /* Cambia a columna */
    align-items: center; /* Centra los elementos */
  }
  
  .texto_diapositiva {
    width: 80%; /* Ajustar el ancho según sea necesario */
    margin-bottom: 20px; /* Añadir margen inferior */
  }
  
  .botones_diapositiva {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Añadir margen superior */
    width: 80%;
    margin-left: 10%;
  }

  .botones_diapositiva a{
    text-decoration: none;
  }
  
  .btn {
    background-color: #00aaff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 30px;
    cursor: pointer;
  }

  .btn:hover {
    background-color: #4ac0fa;
 
  }
  
  .btn2 {
    background-color: rgba(255, 255, 255, 0);
    color: #00aaff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }

  .btn2:hover {
    text-decoration: underline;
    
  }
  
  .slide-images {
    display: flex;
    gap: 10px;
    justify-content: center; /* Centrar las imágenes */
    margin-top: 8px;
    margin-bottom: 8PX;
  }
  
  .slide-images img {
    width: 20%;
    height: 180PX;
    object-fit: cover;
    border-radius: 10px;
  }

  /* Personalizar la flecha siguiente */
.slick-next {
    right: 10px; /* Ajusta la posición según sea necesario */
    z-index: 1;
  }
  
  .slick-next:before {
    font-family: "slick";
    font-size: 40px;
    line-height: 1;
    opacity: 0.75;
    color: #17B8FF !important; /* Cambia el color de la flecha */
  }
  
  /* Personalizar la flecha anterior */
  .slick-prev {
    left: 10px; /* Ajusta la posición según sea necesario */
    z-index: 1;
  }
  
  .slick-prev:before {
    font-family: "slick";
    font-size: 40px;
    line-height: 1;
    opacity: 0.75;
    color: #17B8FF !important; /* Cambia el color de la flecha */
  }

  @media only screen and (min-width: 487px) and (max-width: 767px){
    .slideshow {
      width: 93%;
      margin-left: 0;
      border-radius: 0;
      padding: 13px;
      height: auto;
    }

    .tags{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40%;
      margin-left: 30%;
    }

    .tags_diapositiva{
      font-size: 0.3em;
      
    }

    .slide-images {
      width: 80%;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Creates two columns of equal width */
      grid-gap: 10px; /* Adds spacing between images (optional) */
    }
    
    .slide-images img {
      width: 100%; /* Ensures images fill the available space within each grid cell */
      height: 170px; /* Maintains image aspect ratio */
    }

    .imagen2_guiaturistica{
      display: none;
    }

  }

  
  @media only screen and (max-width: 486px){
    .slideshow {
      width: 93%;
      margin-left: 0;
      border-radius: 0;
      padding: 13px;
      padding-bottom: 10%;
      height: auto;
    }

    .tags{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-left: 10%;
    }
  

    .slide-images {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* Creates two columns of equal width */
      grid-gap: 10px; /* Adds spacing between images (optional) */
    }

    .slide-images img {
      width:80%; /* Ensures images fill the available space within each grid cell */
      height: 170px; /* Maintains image aspect ratio */
      padding-left: 10%;
    }
  }
  