.ContenedorFormulario_eventos{
    background-color: #f7f7f7;
    padding-top: 40PX;
    display: flex;
    flex-direction: row;
    height: 42vh;
}

.contenedor_img1formEv{
    width: 25%;
    flex: 1;
  position: relative; 
}

.img1formEv{
    width:350px;
}

.inputs_formularioEventos{
    display: flex;
    flex-direction: column ;
    align-items: center;
}

.titulo_formularioEventos{
    font-weight: bolder;
    font-size: 2.3em;
    text-align: center;
    width: 70%;
    color: #2AA2FF;
    margin-bottom: 10px;
    z-index: 4;
}

.parrafo_formularioEventos{
    font-size: 1.2em;
    width: 60%;
    margin-left: 6%;
    margin-top: 0;
}

.contenedor_img2formEv{
    flex: 1;
  position: relative; 
}
.img2formEv{
    width:280px;
    position: absolute; /* Asegúrate de que la imagen esté en la posición absoluta */
  bottom: 0; /* Pega la imagen en la parte inferior del contenedor */
  right: 0; /* Ajusta según sea necesario */
}

.inputs_formularioEventos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 49%;
    margin-left: 11%;   
}

.botonEnviar_eventos{
    height: 4.1em;
    background-color: #EF8822;
    border-radius: 5px;
    color: white;
    border: none;
    padding: 0 20px;
    cursor: pointer;

}