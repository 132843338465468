.contenedor_masVisto {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.most-viewed h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.articulo_masVisto {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.articulo_masVisto img {
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #01798460; /* Color y opacidad del filtro */
  transition: background 0.5s ease;
}

.articulo_masVisto:hover .overlay {
  background: #01798400; /* Color transparente al pasar el cursor */
}

.titulo_mv {
  position: absolute;
  bottom: 4px;
  left: 0px;
  width: 50%;
  color: white;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(to right, #017A84, rgba(0, 0, 0, 0));
  padding: 5px 10px;
}

.espacioPubliciario2_revista {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3d3d3;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  width: 205%;
}

/* Media Queries */

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .contenedor_masVisto {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas en tablet */
    gap: 15px;
  }

  .most-viewed h2 {
    font-size: 22px; /* Disminuye el tamaño de la fuente en tablet */
  }

  .titulo_mv {
    font-size: 16px; /* Disminuye el tamaño de la fuente en tablet */
  }

  .espacioPubliciario2_revista {
    height: 30VH; /* Ajusta el ancho del espacio publicitario en móvil */
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .contenedor_masVisto {
    grid-template-columns: 1fr; /* 1 columna en móvil */
    gap: 20px;
  }

  .most-viewed h2 {
    font-size: 20px; /* Disminuye el tamaño de la fuente en móvil */
  }

  .titulo_mv {
    font-size: 14px; /* Disminuye el tamaño de la fuente en móvil */
  }

  .espacioPubliciario2_revista {
    width: 100%;
    height: 28VH; /* Ajusta el ancho del espacio publicitario en móvil */
  }
}

