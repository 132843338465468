.contenedor_Articulo{
    padding-top: 12%;
}

.encabezadoArticulo{
    width: 80%;
    margin-left: 10%;
}

.encabezadoArticulo a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}


.titulo_Articulo{
    margin-bottom: 8px;
    width: 70%;
}

.encabezadoArticulo hr{
    opacity: 30%;
}

.portada_articulo{
    margin-left: 15%;
}

.contenedor_imgPortada{
    width: 80%;
    height:25VH;
    position: relative; /* Necesario para posicionar elementos hijos con position absolute */
    overflow: hidden; /* Asegura que la imagen recortada no sobresalga del contenedor */
}

.portadaImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.categoria_portadaArticulo{
    background-color: #EF8822;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 5px;
    font-size: 1.3em;
    font-weight: 700;
    color: white;
    position: absolute;
    top: 330px;
    left: 16%;
}

.fecha_portadaArticulo{
    margin-top: 5px;
    font-size: 1.2em;
    color: gray;
}

.contenedor_contenidoArticulo{
    width: 70.7%;
    margin-left: 15%;
}

.seccion1_articulo{
    display: flex;
}

.parrafo_secc1{
    width: 60%;
    padding-right: 40px;
    text-align: justify;
}

.contenedor_imgSecArticulo{
    width: 40%;
}

.imgSecArticulo{
    width: 90%;
    height: 90%;
    object-fit: cover;
   
}

.seccion2_articulo{
    padding-right: 3%;
    text-align: justify;
}

.Add1_articulo{
    width: 96%;
    margin-left: 2%;
    background-color: #EF8822;
    height: 140PX;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.secc3_articulo{
    display: flex;
    width: 98%;
    margin-bottom: 70px;
}

.parrafo_secc3{
    width: 64%;
    padding-right: 50px;
    text-align: justify;
}

.Add2_articulo{
    background-color: #EF8822;
    height: 270PX;
    width: 30%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Add3_articulo{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 80px;
    background-color: #EF8822;
    height: 220PX;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .encabezadoArticulo a{
        display: flex;
        flex-direction: column;
    }

    .titulo_Articulo{
        margin-bottom: 8px;
        width: 100%;
    }


.cont_compartirArticulo{
    padding-left: 50%;
}
}

@media only screen and (max-width: 767px) {
    .encabezadoArticulo a{
        display: flex;
        flex-direction: column;
    }

    .titulo_Articulo{
        margin-bottom: 14px;
        width: 100%;
    }

    .cont_compartirArticulo{
        padding-left: 40%;
    }


    .titulo_Articulo{ 
        margin-top: 2em;
        font-size: 2em;
    }

    .categoria_portadaArticulo{
        background-color: #EF8822;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 5px;
        font-size: 1.3em;
        font-weight: 700;
        color: white;
        position: absolute;
        top: 285px;
        left: 6%;
    }

    .portada_articulo{
        width: 90%;
        margin-left:3% ;
    }

    .contenedor_imgPortada{
        width: 100%;
    }

    .contenedor_contenidoArticulo{
        width: 90%;
        margin-left: 5%;
        padding-left: 0;
    }

    .seccion1_articulo{
        display: flex;
        flex-direction: column;
    }

    .parrafo_secc1{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        text-align: justify;
    }
    
    .contenedor_imgSecArticulo{
        width: 100%;
    }

    .imgSecArticulo{
        width: 100%;
        height: 25vh;
    }

    .seccion2_articulo{
        width: 100%;
        padding-right: 0;
    }

    .secc3_articulo{
        display: flex;
        flex-direction: column;
    }

    .parrafo_secc3{
        width: 100%;
        padding-right: 0;
    }

}

