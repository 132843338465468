.contenedorPrincipal_revistaDigital{
    padding: 12% 5% 0 5%;
    background-color: white;
}

.contenedorPrincipal_revistaDigital h3{
    margin-top: 0;
    margin-bottom: 4px;
}

.contenedorPrincipal_revistaDigital hr{
    color: rgb(233, 229, 229);
}

.espacioPublicitario_paginaPrincipal1{
    background-color: rgb(223, 222, 222);
    color: white;
    text-align: center;
    width: 95%;
    margin-left: 2.5%;
    height: 170px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.titulo_lomasvisto{
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 1.7em;

}

.noticias_delMes{
    margin-top: 40px;
    background-color: #E8FBFD;
    padding-top: 2px;
    padding-bottom: 30px;
    margin-bottom: 45px;
}

.noticias_delMes h1{
    margin-left: 30px;
    margin-bottom: 0;
    font-size: 1.5em;
}

.noticias_delMes hr{
    width: 95%;
}



