.galeriaI_sitiosImportantes{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: 10%;
    justify-content: space-between;
}

.imagen_SitiosImp{
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}


.division_sitiosImportantes{
    width: 32%;
}

.imgSimportantes1,
.imgSimportantes4,
.imgSimportantes5 {
   height: 70%;
}

.boton_sitiosImportantes{
    position: relative;
    top: -40px;
    left: 4%;
    border: none;
    border-radius: 40px;
    padding: 5px 15px;
    background-color: #17B8FF;
    color: white;
    font-weight: 900;
    cursor: pointer;
}

.lineaDivisora1_guia{
    margin-top: 10%;
    width: 80%;
    opacity: 30%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .galeriaI_sitiosImportantes{
        display: flex;
        flex-direction: row;
        width: 90%;
        margin-left: 5%;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 487px) and (max-width: 767px) {
    .galeriaI_sitiosImportantes{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: 10%;
        justify-content: space-between;
    }

    .division_sitiosImportantes{
        width: 100%;
    }

    .boton_sitiosImportantes{
    position: relative;
    top: -50px;
    left: 2%;
    border: none;
    border-radius: 40px;
    padding: 5px 15px;
    background-color: #17B8FF;
    color: white;
    font-weight: 900;
    font-size: 1.5em;
    cursor: pointer;
}

}



@media only screen and (max-width: 486px){
    .galeriaI_sitiosImportantes{
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: 5%;
        justify-content: space-between;
    }

    .division_sitiosImportantes{
        width: 100%;
    }

    .boton_sitiosImportantes{
        position: relative;
        top: -50px;
        left: 2%;
        border: none;
        border-radius: 40px;
        padding: 5px 15px;
        background-color: #17B8FF;
        color: white;
        font-weight: 900;
        font-size: 1.3em;

    }
}