.contenedor_membresia{
    background-image: url("https://img.freepik.com/vector-gratis/moderno-fondo-blanco-abstracto-minimalista_84443-8335.jpg?w=2000&t=st=1722285256~exp=1722285856~hmac=208bb4007543c6502a4399fe15a227f250e88fe1ad4bb2bc78c662298d24a7a8");
    background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 0%;
    padding-top: 1%;
    padding-bottom: 3%;
    margin-top: 90px;
    -webkit-box-shadow: 0px 11px 68px -15px rgba(0,0,0,0.65);
    -moz-box-shadow: 0px 11px 68px -15px rgba(0,0,0,0.65);
    box-shadow: 0px 11px 68px -15px rgba(0,0,0,0.65);
}

.contenedor_membresia h3{
    margin-bottom: 0;
    color: #EF8822;
}

.titulos_membresia{
    padding-left: 13%;
}

.subtitulo_vallecard{
    margin-top: 0;
    font-size: 3em;
    color: #EF8822;
}

.div_membresia{
    display: flex;
    flex-direction: row;
}

.beneficios1{
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18vh;
    padding-top: 2%;
    padding-left: 13%;
}

.beneficios2{
    margin-right: 50px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25vh;
}

.cta_membresia{
    text-align: center;
    margin-top: -6%;
}

.modelo_membresia{
    width: 100%;
    margin-top: -10%;
}


.cta_membresia{
    width: 25%;
}

.icono_beneficio{
    width: 40px;
    margin-right: 10px;
}

.beneficio_memb1,
.beneficio_memb2,
.beneficio_memb3,
.beneficio_memb4,
.beneficio_memb5{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.texto_beneficio{
    font-size: 1.1em;
    font-weight: bolder;
    color: #1E77AC;
}

.obten_la_tuya{
    color: #2AA2FF;
}

.boton_vermas_membresia{
    background-color: #EF8822;
    border: none;
    width: 50%;
    height: 40px;
    border-radius: 8px;
    font-weight: bolder;
    font-size: 1.1em;
    color: white;
    cursor: pointer;
}

