.contenedor_articulosRelacionados{
background-color: #E8FBFD;
padding-top: 20px;
padding-bottom: 20px;
margin-bottom: 30px;
}

.contenedor_articulosRelacionados h1{
    margin-left: 9%;
}

.contenedor_articulosRelacionados a{
    color: #EF8822;
}

.tarjetas_articulosRelacionados {
    display: flex; /* Activar flexbox */
    flex-direction: row; /* Disponer los elementos en fila (horizontal) */
    justify-content: space-between; /* Distribuir los elementos con espacio entre ellos */
    overflow: hidden;
    padding-left: 9%;
    margin-right: 10%;
    overflow-x: scroll;
  }
  
  .tarjeta_articuloRelacionado {
    flex: 1 0 27%; /* Ajustar ancho y comportamiento flexible de las tarjetas */
  margin-right: 20px; /* Agregar margen entre tarjetas */
  transition: all 0.3s ease; /* Agregar transición para suavizar los cambios de estilo */
  cursor: pointer;
  }

  .tarjeta_articuloRelacionado:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más intensa al pasar el cursor */
    transform: scale(1.05); /* Aumentar ligeramente el tamaño al pasar el cursor */
  }
  
  .imagen_artRelacionado {
    max-width: 100%;
    height: auto;
  }

  .clasyFech_arcticuloRelacionado{
    display: flex;
    align-items: center;
  }

  .categoria_articuloRelacionado{
    background-color: #EF8822;
    padding: 5px 10px;
    color: white;
    font-weight: 700;
    margin-right: 10px;
  }

  .fecha_articuloRelacionado{
    color: gray;
  }

  .titulo_articuloRelacionado{
    margin-top: -7px;
    margin-bottom: 0;
  }

  .descripcion_articuloRelacionado{
    font-size: 0.9em;
    margin-top: 7px;
  }