.contenedor_propiedades {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    height: auto;
}

.propiedad_menu{
    background-color: #F5F5F5;
    border: 1px solid #c0bebe;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.propiedad_menu img{
    width: 40%; /* Or desired width */
  height: auto;
  object-fit: cover;
  border-radius:6px 0 0 6px ;
}

.informacion_propiedad{
    width: 55%;
    text-align: left;
    margin-left: 2.5%;
}



.informacion_propiedad h2,
.informacion_propiedad p{
   margin-bottom: 10px;
   margin-top: 7px;
}

.link_descPropiedad{
    text-decoration: none;
    color: black;
}

.detalles_propiedad{
    display: flex;
    justify-content: space-between;
    width: 50%;
    list-style: none;
    margin-left: 0;
    margin-left: -6%;
    margin-bottom: 7px;
}

.tags_propiedades{
    margin-bottom: 14px;
}

.botones_propiedadMenu{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.boton_llamar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; 
    border: 1px solid #EF8822;
    text-decoration: none;
    padding: 4px 2px;
    width: 7%;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 5px;
}

.boton_llamar:visited {
    color: black; /* Inherit the text color of its parent element */
    text-decoration: none; /* Remove underline */
  }


.boton_contacto{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EF8822;
    padding: 5px 5px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 6px;
    width: 150px;
}

.boton_contacto span{
    margin-left: 5px;
}

.boton_contacto:visited {
    color: white; /* Inherit the text color of its parent element */
    text-decoration: none; /* Remove underline */
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .informacion_propiedad p{
        font-size: 0.8em;
    }
  }

  @media only screen and (min-width: 487px) and (max-width: 767px) {
    .informacion_propiedad p{
        font-size: 0.6em;
    }
  }

  @media only screen and (max-width: 486px){
    .propiedad_menu{
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .propiedad_menu img{
        width: 100%;
    }

    .informacion_propiedad{
        width: 90%;
        text-align: left;
        margin-left: 2.5%;
    }
  }