.contenedor_bannerGuia {
  margin-bottom: -30%;
}

.banner_superiorGuia {
  margin-top: -12%;
  width: 100%;
  position: relative;
  z-index: 1;
}

.contenedor_compartirGuia {
  padding-left: 70%;
  position: relative;
  z-index: 2;
}

.textoPrincipal_guia {
  margin-top: 16%;
  align-items: center;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 4vw; /* Corregido de 4cqw a 4vw */
}

.tituloBienvenidosGuia {
  color: #55C3FF;
  font-size: 3em;
  text-align: center;
}

.textoPrincipal_guia p {
  font-size: 1.3em;
}

.tituloGuia {
  padding-left: 10%;
  padding-right: 40%;
  color: white;
  position: relative;
  z-index: 3;
}

.tituloGuia h1,
.tituloGuia h2,
.tituloGuia h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.tituloGuia h1 {
  font-size: 3em;
  margin-top: -13px;
}

.tituloGuia h2 {
  font-size: 2em;
}

.tituloGuia h3 {
  font-size: 1.5em;
}

.tituloGuia hr {
  border: none;
  opacity: 30%;
  border-top: 2px solid #fff; /* Cambia a blanco para contraste con el fondo oscuro */
  margin: 10px 0;
  width: 80%; /* Ajusta el ancho según sea necesario */
  margin-top: 3px;
  margin-bottom: 5px;
}

.contenedor_logoGuia {
  position: relative;
  z-index: 4;
  margin: -5%;
  width: 100%;
}

.logoGuia {
  width: 30%;
  margin-left: 60%;
}

.titulo_sitiosImperdibles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.titulo_sitiosImperdibles h2 {
  margin-bottom: 5px;
}

.barra_titulos {
  background-color: #EF8822;
  width: 120px;
  height: 6px;
  margin-bottom: 20px;
  margin-top: 0;
}

.acercade_Valle {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: 10%;
  align-items: center;
  margin-top: 20px;
}

.acercade_Valle iframe{
  width: 100%;
}

.acercade_Valle p {
  width: 80%;
  padding-right: 2%;
}

.acercadeValle_contenedor {
  width: 70%;
}

.contenedor_comentariosVisitanes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #efeeee;
  background-size: cover;
  margin-top: 60px;
}

.contenedor_comentariosVisitanes h2 {
  margin-bottom: 0;
  color: #55C3FF;
}

.contenedor_comentariosVisitanes p {
  margin-top: 0;
  margin-bottom: 40px;
}

.espacioPublicitario1_guia {
  width: 80%;
  height: 30vh;
  background-color: #EF8822;
  margin-left: 10%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.imagenInferior {
  width: 100%;
  margin-top: -200px;
}

.logo_guiaTur_inferior {
  width: 25%;
  position: relative;
  left: 35%;
  bottom: -150px;
  z-index: 3;
}

@media only screen and (min-width: 781px) and (max-width: 1024px) {

  .acercade_Valle {
    display: flex;
    flex-direction: column;
  }

  .acercadeValle_contenedor {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 7px; /* Ajusta el espacio entre los elementos según tus necesidades */
    justify-self: center;
    text-align: center;
  }

  .acercadeValle_texto:nth-child(1) {
    grid-column: 1 / 2;
  }

  .acercadeValle_texto:nth-child(2) {
    grid-column: 2 / 3;
  }

  .acercadeValle_texto:nth-child(3) {
    grid-column: 1 / 3;
  }

  .acercade_Valle p {
    width: 95%;
    padding-right: 0;
  }
}

@media only screen  and (min-width: 487px) and (max-width: 780px) {
  .banner_superiorGuia {
    margin-top: 0;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .acercade_Valle {
    display: flex;
    flex-direction: column;
  }

  .tituloGuia {
    padding-left: 6.5%;
    margin-top: -24%;
    padding-right: 0;
    color: white;
    position: relative;
    z-index: 3;
    text-align: center;
  }

  .tituloGuia hr {
    border: none;
    opacity: 30%;
    border-top: 2px solid #fff; /* Cambia a blanco para contraste con el fondo oscuro */
    margin: 0;
    width: 90%; /* Ajusta el ancho según sea necesario */
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .tituloGuia h2 {
    font-size: 1.2em;
    margin-bottom: 8px;
  }

  .tituloGuia h1 {
    font-size: 2em;
  }

  .logoGuia {
    width: 60%;
    margin-left: 23%;
    margin-top: 15%;
  }

  .textoPrincipal_guia {
    margin-top: 8%;
  }

  .tituloBienvenidosGuia h2 {
    margin-top: -6%;
  }

  .contenedor_compartirGuia {
    display: none;
  }

}


@media only screen and (max-width: 486px){
  .banner_superiorGuia {
    margin-top: 0;
    width: 100%;
    position: relative;
    z-index: 1;
    height: 30vh;
  }

  .acercade_Valle {
    display: flex;
    flex-direction: column;
  }

  .tituloGuia {
    display: none;
  }

  .contenedor_compartirGuia {
    display: none;
  }

  .contenedor_logoGuia{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4%;
  }

  .logoGuia{
    width: 80%;
    margin-left: 0;
  }

  .textoPrincipal_guia{
    padding-left: 3%;
    padding-right: 3%;
  }

  .acercadeValle_contenedor{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .acercade_Valle{
    width: 100%;
    margin-left: 0;
    display: flex;
    text-align: center;

  }

  .acercade_Valle p{
    width: 100%;
  }

  .espacioPublicitario1_guia{
    margin-bottom: 120px;
  }

  .logo_guiaTur_inferior{
    bottom: 5px;
  }

}