.contenedor_paginaPropiedad hr {
  opacity: 30%;
}

.contenedor_paginaPropiedad {
  padding-top: 13.6%;
}

.descripcionGeneral_propiedad img {
  border-radius: 10px;
}

.encabezadoArticulo div {
  display: flex;
  flex-direction: row;
}

.titulo_propiedad {
  color: #2AA2FF !important;
  margin-top: 0;
  margin-bottom: 0;
}

.subtitulo_Negocio{
  margin-top: 0;
  margin-left: 4%;
  margin-bottom: 0;
}

.logoNegocio_pagina{
  margin-left: 30%;
  height: 30vh;
}

.productosServicios_contenedor{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.galeriaNegocio {
  display: flex;
  margin-left: 0%;
  height: 350px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.foto_principalNegocios {
  width: 50%;
  height: 350px;
  object-fit: cover;
}

.fotos_secundariasNegocios {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin-left: 10px;
}

.fotoSecundaria1Negocios,
.fotoSecundaria2Negocios,
.fotoSecundaria3Negocios,
.fotoSecundaria4Negocios {
  flex: 1 0 47%;
  max-width: 47%;
  box-sizing: border-box;
  object-fit: cover;
}

.infoproPiedades {
  display: flex;
  margin-left: 8%;
}

.detallesPropiedades {
  width: 56%;
}

.contactoPropiedades {
  width: 40%;
}

.contenedor_ubicacion {
  display: flex;
  align-items: center;
}

.contenedor_ubicacion span {
  margin-right: 7px;
}

.contenedor_ubicacion h5 {
  margin-top: 0;
}

.contacta_contenedor {
  border: 0.5px solid rgba(0, 0, 0, 0.695);
  border-radius: 20px;
  width: 70%;
  margin-left: 30px;
  padding: 25px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.contacta_contenedor a {
  display: flex;
  flex-direction: row;
}

.boton_terminosCondiciones {
  cursor: pointer;
}

.boton_terminosCondiciones:hover {
  text-decoration: underline;
}

.contenedor_telefonoFormulario {
  display: flex;
  flex-direction: row;
  border: 0.5px solid rgba(0, 0, 0, 0.695);
  border-radius: 20px;
  width: 70%;
  margin-left: 30px;
  padding: 25px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 10px;
  align-items: center;
}

.contenedor_logoTelefono {
  width: 40%;
}

.logo_telefono {
  width: 80%;
}

.informacion_telefono div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.informacion_telefono h4 {
  margin-bottom: 5px;
}

.informacion_telefono h3 {
  margin-right: 5px;
  margin-top: 5px;
}

.informacion_telefono p {
  font-weight: bolder;
  font-size: 0.7em;
  color: gray;
  cursor: pointer;
  margin-top: -2px;
}

.addPropiedad1 {
  color: white;
  background-color: #DDDDDD;
  border-radius: 20px;
  width: 70%;
  height: 320px;
  margin-left: 30px;
  padding: 25px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .contenedor_paginaPropiedad {
    padding-top: 15%;
  }

  .encabezadoArticulo div {
    display: flex;
    flex-direction: row;
  }

  .titulo_Articulo {
    width: 50%;
  }

  .foto_principal {
    width: 40%;
  }

  .galeriaPropiedad {
    margin-left: 11%;
  }

  .infoproPiedades {
    display: flex;
    flex-direction: column;
  }

  .detallesPropiedades {
    width: 90%;
  }

  .contactoPropiedades {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  
}

@media only screen and (max-width: 767px) {
  .contenedor_ecabezadoHospedaje button {
    display: none;
  }

  .encabezadoArticulo div {
    display: flex;
    flex-direction: row;
  }

  .titulo_Articulo {
    margin-top: 0;
    text-align: center;
  }

  .logoNegocio_pagina{
    margin-left: 20%;
    height: 30vh;
  }

  .foto_principal {
    width: 40%;
  }

  .galeriaPropiedad {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    width: 90%;
  }

  .foto_principal {
    width: 90%;
    margin-bottom: 10px;
  }

  .fotos_secundarias {
    margin-left: 0;
  }

  .fotoSecundaria1,
  .fotoSecundaria2,
  .fotoSecundaria3,
  .fotoSecundaria4 {
    flex: 1 0 44%;
    max-width: 44%;
    box-sizing: border-box;
  }

  .infoproPiedades {
    display: flex;
    flex-direction: column;
  }

  .detallesPropiedades {
    width: 90%;
  }

  .contactoPropiedades {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .contacta_contenedor {
    margin-left: 0;
    width: 90%;
  }

  .contenedor_telefonoFormulario {
    width: 90%;
    margin-left: 0;
  }

  .addPropiedad1 {
    margin-left: 0;
    width: 90%;
    text-align: center;
  }
}
