.cont_sitioConstruccion{
    display: flex;
    flex-direction: row;
    padding-top: 180px;
}

.constructor{
    width: 30%;
    padding-left: 10%;
}

.texto_enConstruccion{
    text-align: left;
    padding-left: 5%;
}

.texto_enConstruccion h1{
    font-size: 3em;
    margin-bottom: 5px;
}

.texto_enConstruccion p{
    font-size: 1.4em;
    width: 60%;
    margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .constructor{
        padding-left: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .cont_sitioConstruccion{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 120px;
    }

    .constructor{
        width: 80%;
    }

    .texto_enConstruccion{
        text-align: center;
    }

    .texto_enConstruccion h1{
        font-size: 2.5em;
        margin-bottom: 5px;
    }

    .texto_enConstruccion p{
        font-size: 1em;
        width: 80%;
        padding-left: 10%;
    }
    
}
