.diapos_comentariosVisitantes{
    width: 70%;
    display: flex;
    flex-direction: row;
    padding-left: 15%;
    padding-right: 15%;
}

.diapositiva_Visitantes{
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: row;
}

.diapositiva_Visitantes h2{
color: #55C3FF;
font-size: 3em;
margin: 20px;
}

.letraPrincipal{
    font-weight: bolder;
}

.nombreVisitante{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

}

.nombreVisitante h3{
color: #EF8822;
}

.nombreVisitante img{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid black;
    margin-right: 20px;
}

.botonDiaposVisitante{
    height: 40px;
    width: 40px;
    background: none;
    border:1px solid #55C3FF;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.botonDiaposVisitante span{
    color: #55C3FF;
    padding-left: 10px;
    cursor: pointer;
}

@media only screen and (min-width: 781px) and (max-width: 1024px) {

}

@media only screen  and (min-width: 487px) and (max-width: 780px) {}

@media only screen and (max-width: 486px){
    .contenedor_comentariosVisitanes{
        text-align: center;
    }

    .diapos_comentariosVisitantes{
        width: 94%;
        margin: 0;
        display: flex;
        flex-direction: row;
        padding-left: 3%;
        padding-right: 3%;
        align-items: center;
    }

    .diapositiva_Visitantes{
        padding-left: 2%;
        padding-right: 2%;
    }
}