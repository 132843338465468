.contenedor_descargarRevista{
   display: flex;
   height: 35vh;
   -webkit-box-shadow: 5px 0px 159px -42px rgba(0,0,0,0.4);
   -moz-box-shadow: 5px 0px 159px -42px rgba(0,0,0,0.4);
   box-shadow: 5px 0px 159px -42px rgba(0,0,0,0.4);
   width: 127%;   
   margin-left: -15%;
   margin-top: 40px;
}

.contenedor_cta_download{
    padding: 2%;
    padding-left: 18%;
    width: 50%;
}

.titulo_downloadRevista,
.antetitulo_downloadRevista{
    color: #EF8822;
    margin-bottom: 0;
    margin-top: 0;
}

.titulo_downloadRevista{
    font-size: 2.5em;
    margin-bottom: 70px;
}

.boton_descargarRevista{
 width: 30% !important;
 left: 2%;
 padding-left: 0px !important;
}

.boton_descargarRevista p{
    font-weight: 900;
}

.boton_pdf{
    width: 70px;
    margin-left: -7%;
}

.revista_foto{
    height: 120%;
    margin-top: -2%;
    margin-left: -10%;
}

