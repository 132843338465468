.diapo_RE{
    min-width: 250px;
    display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-right: 30px;
}

.foto_diapo_re{
    width: 100%;
    height: auto;
}

.titulo_dre{
    background-color: rgb(255, 255, 255);
    width: 80%;
    margin-top: -10%;
    padding: 10px;
    padding-top: 6px;
    border-radius: 0px 50px 50px 0px;
}

.titulo_dre h2{
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 0;
    color: #0E5A89;
}

.titulo_dre h3{
    font-size: 0.8em;
    margin-bottom: 0;
    margin-top: 0;
}

.con_botones_dre{
    margin-left: 50%;
}

.con_botones_dre button{
    background-color: #17B8FF;
    border: none;
    color: white;
    height: 30px;
}

.vermas_hospedaje{
    border-radius: 100%;
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.btn_reservar_inicio{
    border-radius: 5px;
    cursor: pointer;
}
