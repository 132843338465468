.contenedor_propiedadesSimilares{
    padding-left: 9%;
    padding-right: 9%;
    margin-top: 60px;
    margin-bottom: 90px;
}

.tarjetas_propiedadesSimilares{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    overflow-x: scroll;
}

.propiedadSimilar{
    background-color: #F5F5F5;
    border: 0.4px solid #707070b9;
    border-radius: 10px;
    flex: 1 0 30%;
    margin-right: 20px;
}

.imagen_PropiedaSimilar{
    width: 100%;
    border-radius:10px 10px 0 0  !important;
}

.datos_propiedadSimilar{
    display: flex;
    flex-direction: row;
    padding-left: 4%;
    padding-right: 4%;
}

.logo_propiedadSimilar{
    max-width: 70px;
}

.info_propiedadSimilar a{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.info_propiedadSimilar h3{
    margin-top: 6px;
    margin-bottom: 0;
}

.info_propiedadSimilar h4{
    color: #707070;
    width: 90%;
    font-size: 0.8em;
    margin-top: 5px;
    margin-bottom: 7px;
}