.contenedor_eventosTop {
  background-color: #f7f7f7;
  text-align: center;
  padding: 2px 5%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.box_evento {
  width: 30%;
  height: 38vh;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 40px;
  -webkit-box-shadow: 4px 3px 35px -22px rgba(0,0,0,0.63);
  -moz-box-shadow: 4px 3px 35px -22px rgba(0,0,0,0.63);
  box-shadow: 4px 3px 35px -22px rgba(0,0,0,0.63);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
}

.box_evento:hover {
  transform: scale(1.05);
}

.imagen_evento {
  width: 100%;
  height: 65%;
  border-radius: 9px 9px 0 0;
  object-fit: cover;
}

.informacion_evento {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.fecha_evento {
  text-align: center;
}

.numero_fecha {
  font-weight: 900;
  color: #55c3ff;
  font-size: 1.5em;
}

.mes_fecha {
  font-size: 1.3em;
  color: gray;
}

.titulosDireccion_evento {
  text-align: left;
  width: 50%;
}

.titulo_eventoBox {
  font-weight: bolder;
  margin: 0;
  font-size: 110%;
}

.dirección_eventoBox {
  font-weight: bolder;
  color: rgb(167, 167, 167);
  font-size: 0.8em;
}

.btnyPrecio_evento {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.precio_evento {
  font-weight: bolder;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.boton_info_eventoBox {
  background-color: #17B8FF;
  border-radius: 100%;
  border: none;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 1.3em;
  cursor: pointer;
}

/* Media Queries */

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .box_evento {
    width: 45%; /* Ajustar a dos columnas en tablets */
    height: auto; /* Ajuste la altura según el contenido */
  }

  .informacion_evento {
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    padding: 15px;
  }

  .numero_fecha {
    font-size: 1.8em;
  }

  .mes_fecha {
    font-size: 1.3em;
  }

  .titulo_eventoBox {
    font-size: 1.1em;
  }

  .dirección_eventoBox {
    font-size: 0.9em;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .box_evento {
    width: 100%; /* Ajustar a una columna en móviles */
    height: 35vh; /* Ajuste la altura según el contenido */
  }

  .informacion_evento {
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    padding-left: 4%;
    padding-right: 4%;
  }

  .numero_fecha {
    font-size: 2em;
  }

  .mes_fecha {
    font-size: 1.3em;
  }

  .titulo_eventoBox {
    font-size: 130%;
  }

  .dirección_eventoBox {
    font-size: 0.8em;
  }
}
