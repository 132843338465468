body {
  scroll-behavior: smooth;
}
.Encabezado {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: rgba(185, 239, 248, 0.482);
  padding-bottom: 17px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.6s cubic-bezier(0.1, 0, 0.58, 1); /* Transición suave */
  -webkit-box-shadow: 2px 10px 11px -11px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 10px 11px -11px rgba(0, 0, 0, 0.29);
  box-shadow: 2px 10px 11px -11px rgba(0, 0, 0, 0.29);
}

.seccion1_encabezado {
  display: flex;
  flex-direction: row;
  padding-top: 2%;
}

.Encabezado.scroll .seccion1_encabezado {
  padding-top: 1%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 19%;
  width: 65%;
}

.Encabezado.scroll .logo {
  transform: translateX(-40%); /* Ejemplo de cómo mover el logo */
  width: 50%;
  transition: transform 0.5s cubic-bezier(0.1, 0, 0.58, 1); /* Transición suave para el logo */
  margin-right: 14%;
}

.logo_principal {
  width: 450px;
  transition: transform 0.5s cubic-bezier(0.1, 0, 0.58, 1); /* Transición suave para el logo */
}

.Encabezado.scroll .logo_principal {
  width: 400px;
}

.botonContacto_navegacion {
  border: none;
  border-radius: 30px;
  padding-left: 50%;
  padding-right: 50%;
  background-color: rgb(144, 213, 243);
  margin-top: 30%;
  cursor: pointer;
}

.botonContacto_navegacion:hover{
  background-color: rgb(2, 15, 68);
}

.botonContacto_navegacion p {
  font-weight: 700;
  color: rgb(255, 255, 255);
  margin: 7px 0;
}

.linea_header {
  width: 80%;
  opacity: 20%;
  margin-bottom: 10px;
  border: 0.5px solid rgb(154, 221, 246);
}

.seccion2_encabezado {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 20%;
}

.link_principal {
  text-decoration: none;
  color: black;
}

.link_principal:hover {
  color: #fffefe;
  text-shadow: 1px 1px 3px #888888c4;
}

.link_principal.active,
.link_opcion.active {
  font-weight: bold;
  color: #55c3ff; /* Puedes ajustar este color según tu preferencia */
}


.turismo_desplegable {
  position: relative;
}

.turismo_button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  color: inherit;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.turismo_button:hover{
  color: #fffefe;
  text-shadow: 1px 1px 3px #888888c4;
}

.turismo_opciones {
  display: none;
  position: absolute;
  width: 150px;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  margin-top: 3px;
}

.turismo_desplegable .turismo_opciones {
  display: block;
}

.link_opcion {
  display: block;
  padding: 8px 16px;
  color: black;
  text-decoration: none;
}

.link_opcion:hover {
  background-color: #f1f1f1;
}

.link_opcion:hover {
  color: #55c3ff;
  border-radius: 5px;
}


.hamburger_menu {
  display: none;
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: none;
  color: black;
  margin-left: auto;
}

.seccion2_encabezado {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 20%;
}

@media only screen and (max-width: 1024px) {

  .Encabezado{
    height: 10vh;
    background-color: rgba(234, 246, 248, 0.482);
  }

  .logo{
    margin-left: 0;
    padding-top: 10px;
  }

  .Encabezado.scroll .logo {
    transform: translateX(-0%); /* Ejemplo de cómo mover el logo */
    transition: transform 0.5s cubic-bezier(0.1, 0, 0.58, 1); /* Transición suave para el logo */
    margin-left: 7%;
  }

  .Encabezado.scroll .logo_principal {
    width: 320px;
  }


  .logo_principal{
    width: 320px;
  }

  .botonContacto_navegacion{
    display: none;
  }

  .hamburger_menu {
    display: block;
    color: rgb(0, 0, 0);
    margin-right: 20px;
  }

  .hamburger_menu span{
    font-size: 1.4em;
  }

  .seccion2_encabezado {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0;
    font-size: 1.2em;
  }

  .seccion2_encabezado.open {
    display: flex;
    opacity: 100%;
    background-color: rgba(220, 245, 249, 0.931);
    padding-bottom: 22px;
    margin-top:-7px;
  }

  .link_principal {
    padding: 15px;
    width: 100%;
    text-align: center;
  }

  .turismo_button{
    font-size: 1em;
    margin-top: 5px;
  }

  .turismo_opciones {
    position: static;
    box-shadow: none;
    width: 100%;
  }

  .popupContacto{
    position: fixed; /* Fija el popup en la ventana */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  }

  .popup-innerContacto{
    background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

 
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Encabezado.scroll .logo {
    transform: translateX(-0%); /* Ejemplo de cómo mover el logo */
    transition: transform 0.5s cubic-bezier(0.1, 0, 0.58, 1); /* Transición suave para el logo */
    margin-left: 5%;
  }

  .Encabezado{
    height: 15vh;
  }

  .logo{
    margin-left: 0;
  }

  .logo_principal{
    width: 380px;
  }

  .hamburger_menu {
    display: block;
    margin-right: 40px;
  }

  .seccion2_encabezado {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .seccion2_encabezado.open {
    display: flex;
    opacity: 100%;
    background-color: rgba(185, 239, 248, 0.755);
    padding-bottom: 22px;
    margin-top: 25px;
  }

  .link_principal {
    padding: 15px;
    width: 100%;
    text-align: center;
  }

  .turismo_opciones {
    position: static;
    box-shadow: none;
    width: 100%;
  }


}

@media only screen and (max-width: 767px){
  .logo_principal{
    max-width: 220px;
  }

  .Encabezado{
    height: 9vh;
  }

  .hamburger_menu {
    display: block;
    margin-right: 30px;
    margin-top: 10px;
  }



}
