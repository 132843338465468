.contenedor_fotoprincipal{
    width: 100%; /* 1/3 del ancho de la página */
    height: 48vh; /* Altura completa de la pantalla */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Asegura que la imagen no se salga del contenedor */
    position: relative; /* Para asegurar que cualquier contenido futuro se coloque correctamente */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    box-sizing: border-box; /* Incluye padding y border en el ancho y alto */
}

.foto_principalInicio{
    width: 100%;
    height: auto;
    object-fit: cover; /* Ajusta la imagen para que cubra todo el contenedor */
    display: block; /* Evita el espacio inferior debajo de la imagen */
}

.titulo_novedades{
    padding-left: 6%;
}

.boton_verRevista{
    left: 40%;
    font-size: 1em;
}
.boton_verGuia{
    left: 40%;
}


.icono_revista{
    margin-right: 10px;
}

.titulo_Spots{
    margin-left: 6%;
    margin-bottom: 0;
    margin-top: 1em;
}

.subtitulo_Spots{
    margin-left: 6%;
    margin-top: 0;
    font-size: 2em;
}

.contenedor_diap_propiedades{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: scroll; /* Permite desplazamiento horizontal */
}

.antetitulo_eventos{
    text-align: center;
    margin-left: 3%;
    margin-bottom: 0;
    margin-top: 60px;
}

.eventos_titulo{
    font-size: 2.3em;
    text-align: center;
    margin-right: 3%;
    margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .boton_verRevista{
        left: 36.3%;
        font-size: 1em;

    }
}

@media only screen and (min-width: 487px) and (max-width: 767px) {
    .boton_verRevista{
        left: 28%;
        font-size: 1em;
    }

    .boton_verGuia{
        left: 28%;
        font-size: 1em;
    }

    .titulo_Spots{
        font-size: 1.7em;
        text-align: center;
        padding-right:7%;
    }

    .subtitulo_Spots{
        font-size: 1.5em;
        text-align: center;
        padding-right:10%;
    }
}

@media only screen and (max-width: 486px){
.contenedorInicio{
    padding-top: 18%;
}

.contenedor_fotoprincipal{
    display: none;
}

.boton_verRevista{
    left: 20%;
    font-size: 1em;
}

.boton_verGuia{
    left: 20%;
}


}

