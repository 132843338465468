.nuevas_noticias {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.articulo_principal {
  position: relative;
  flex: 2;
  margin-right: 20px;
  margin-left: 25px;
  cursor: pointer;
}

.articulo_principal img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.contenido_noticiaPrincipal {
    background: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    position: absolute;
    color: white;
    padding: 10px;
    top: 0;
    transition: height 0.6s ease; /* Añade la transición */
  }
  
  .contenido_noticiaPrincipal:hover {
    background: linear-gradient(to bottom, #00000000, rgba(0, 0, 0, 0));
  }

.categoria_noticiaPrincipal{
    background-color: #EF8822;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2px 0;
    font-size: 12px;
    font-weight: bold;
    width: 200px;
    margin-bottom: 2%;
}

.titulo_articuloPrincipal{
    font-size: 24px;
    margin: 5px 0;
}

.fecha_articuloPrincipal{
    font-size: 14px;
}

.articulos_laterales{
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.articuloLateral{
    display: flex;
  position: relative;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.articuloLateral:hover {
    transform: scale(1.01); /* Escala del aumento de tamaño */
}

.articuloLateral img{
  width: 38%;
  height: 96px;
  display: block;
  margin-right: 10px;
  object-fit: cover;
}

.categoria_articuloLateral{
    font-weight: bold;
    color: #EF8822;
}

.date_articuloLateral{
    color: gray;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .nuevas_noticias {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

  }

  .articulo_principal img {
    width: 100%;
    height: 40vh;
    display: block;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .articulos_laterales{
    flex: 2;
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding-left: 3.7%;
    padding-right: 3%;
  }

  .articuloLateral{
    display: flex;
    flex-direction: column;
  }
  .articuloLateral img{
    width: 100%;
    height: 160px;
  }


  
}

@media only screen and (min-width: 487px) and (max-width: 767px) {
  .nuevas_noticias {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .articulo_principal img {
    width: 100%;
    height: 36vh;
    display: block;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .articulos_laterales{
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 5%;
    padding-right: 4%;
  }

  .articuloLateral{
    display: flex;
    flex-direction: column;
  }
  .articuloLateral img{
    width: 100%;
    height: 160px;
  }
}

@media only screen and (max-width: 486px){
  .nuevas_noticias {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .articulo_principal{
    width: 96%;
    margin-left: 2%;
  }

  .articulo_principal img {
    width: 100%;
    height: 40vh;
    display: block;
    object-fit: cover;
    margin-bottom: 10px;
  }

}
